@import url(https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap);
/* ///////////////////////////////// */
/* //////////// RESET ////////////// */
/* ///////////////////////////////// */

*,
*::before,
*::after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    font-family: 'Source sans pro',sans-serif;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

html {
    overflow-x: hidden;
    overflow-y: scroll;
}

body {
    min-height: 100vh;
    width: 100vw;
    flex-direction: column;
    padding:0;
	margin: 0;
    background-repeat: no-repeat;
}

body > #root > div {
    height: 100%;
    min-height: 100vh;
}

ol, ul {
    list-style: none;
    margin: 0px;
}

h1 {
    margin: unset;
    -webkit-margin-before: unset;
            margin-block-start: unset;
    -webkit-margin-after: unset;
            margin-block-end: unset;
}
